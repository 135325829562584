<template>
  <div :class="`maind ${theme_checked ? 'night' : ''}`" id="main-exchange">
    <!-- Webiste View -->
    <section class="exchange_main_sec ">
      <div class="container-fluid exchange">
        <div class="row">
          <div class="col-md-12 py-lg-2">

            <div class="row inner-row g-lg-5 gx-5">
              
              <div class="col-md-12 col-lg ps-lg-0">
                <div class="row gy-lg-3">
                  <div class="col-lg-12 col-12 p-0 ps-lg-3 order-2 order-lg-1">
                    <div class="row">
                      <div class="col-12 col-lg-9 mb-2 pe-lg-1">
                        <ExchangeUpperBox :key="top_bar_key" />
                        <ChartNew :key="chart_key" />
                      </div>
                      <div class="col-12 col-lg-3 ps-lg-1">
                        <BuySell :key="buy_sell_key" />
                        <div class="buy_sell_col">
                          <ul class="nav nav-tabs border-0 rounded" id="left-tab" role="tablist">
                            <li class="nav-item col-6" role="presentation">
                              <button class="nav-link active rounded fs-14 border-0 w-100" id="pills-Order-tab"
                                data-bs-toggle="pill" data-bs-target="#pills-Order" type="button" role="tab"
                                aria-selected="true" aria-controls="pills-Order">Order Book</button>
                            </li>
                            <li class="nav-item col-6" role="presentation">
                              <button class="nav-link rounded w-100 fs-14 border-0" id="pills-History2-tab"
                                data-bs-toggle="pill" data-bs-target="#pills-History2" type="button" role="tab"
                                aria-selected="false">History</button>
                            </li>
                          </ul>
                          <div class="tab-content mt-3" id="pills-tabContent">
                            <OrderDepth :key="order_depth_key" />
                            <TradeHistory :key="trade_history_key" />
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12" v-if="loggedIn">
                        <MainOrders/>
                      </div>
                    </div>
                  </div>
                
                </div>

              </div>
            </div>
          </div>
        </div>
        <!--row-->
      </div>
      <!--container-fluid-->
    </section>
    <!--exchange_main_sec-->
  </div>
</template>

<script>
import ExchangeUpperBox from "@/components/OldExchangeUpperBox.vue";
import TradeHistory from "@/components/OldTradeHistory.vue";
import OrderDepth from "@/components/OldOrderDepth.vue";
import MainOrders from "@/components/Orders/MainOrders";
import BuySell from "@/components/OldBuySellComponent.vue";
import ChartNew from "@/components/OldChartNew.vue";
import ApiClass from "../api/api";

export default {
  name: "Exchange",
  components: {
    ExchangeUpperBox,
    ChartNew,
    OrderDepth,
    MainOrders,
    BuySell,
    TradeHistory,
  },
  data() {
    return {
      trade_history_key: 1,
      order_depth_key: 1,
      top_bar_key: 1,
      chart_key: 1,
      buy_sell_key: 1,
      theme_checked: false,
      loggedIn: false,
    };
  },

  created() {
    let c_theme = localStorage.getItem("theme_checked");
    this.theme_checked = c_theme == "true" ? true : false;

    // Change Chart Color
    let chartOptions = this.theme_checked
      ? this.chartColor("#0000", "#808080", "#808080")
      : this.chartColor("#ffffff", "#000000", "#d6dcde");
    this.$store.commit("SET_CHART_THEME", chartOptions);
    if (localStorage.getItem("token")) {
      this.loggedIn = true;
    }
    // console.log( this.trade_history_key,this.order_depth_key,this.top_bar_key,this.chart_key);
    //  this.trade_history_key =  this.order_depth_key = this.top_bar_key = this.chart_key = 1;
  },
  async mounted() {
    let ress = await ApiClass.getNodeRequest("dashboard/all-symbols", false);
    ress = ress.data;
    // this.$store.commit("SET_EXCHANGE_LIST", ress.data);
    console.log("in condition excvhange ");

    let c_slug = this.$route.params.slug;
    if (!ress.data.includes(c_slug)) {
      location.replace("/spot/BTCUSDT");
    }
  },
  watch: {
    $route: function () {
      this.trade_history_key = this.trade_history_key + 1;
      this.order_depth_key = this.order_depth_key + 1;
      // this.top_bar_key = this.top_bar_key + 1;
      this.chart_key = this.chart_key + 1;
      this.buy_sell_key = this.buy_sell_key + 1;
    },
  },
};
</script>
<style>
#myTab2.nav-tabs .nav-link {
  color: var(--black) !important;
}

#myTab2.nav-tabs .nav-item button.active {
  color: var(--buy-active-color) !important;
}

/* #left-tab .nav-link{
  font-weight: 550;
} */
#left-tab .nav-link.active {
  background-color: var(--bg4) !important;
  border: 0px !important;
  color: var(--onsurface) !important;
}

button .nav-link:hover {
  border: 0px !important;
  outline: none !important;
}

button .nav-link:focus-visible {
  border: 0px !important;
  outline: none !important;
}

#pills-tabContent #pills-History2,
#pills-tabContent #pills-Order {
  /* background-color: #0d1418; */
  background-color: var(--container-theme);
  /* height: 100%; */
  /* min-height: 32rem; */
}
</style>

