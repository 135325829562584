<template>
  <div class="modal" id="cancelmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm card-tm modal-dialog-centered">
      <div class="modal-content card">
        <div class="modal-header border-0">
          <!-- <h5 class="modal-title" id="exampleModalLabel">Are you sure want to logout ?</h5> -->
          <button type="button" class="btn-close fs-8 border rounded-circle" data-bs-dismiss="modal" aria-label="Close" ref="Close"></button>
        </div>
        <div class="modal-body p-3">
          <div class="col-md-12">
            <div class="row justify-content-center text-center gy-4">
              <!-- <img :src="require(`@/assets/images/icons/exclamation.svg`)" style="width:40%;" class="m-0" /> -->
              <div class="px-3">
                <p>Are you want to cancel your order?</p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center p-2">
          <div class="d-flex justify-content-around w-100">
            <button type="button" class="btn w-50 btn-primary" @click="cancelOrder(orderDetail.id)">Yes</button>
            <button type="button" class="btn w-50 text-primary fw-bold" @click="$refs.Close.click();">No</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { Modal } from "bootstrap";
import ApiClass from "@/api/api";
export default {
  name: "Cancelmodal",
  props: ["orderDetail"],
  data() {
    return {
      theModal: null,
    };
  },
  mounted() {
    this.theModal = new Modal(document.getElementById("cancelmodal"));
  },
  methods: {
    async cancelOrder(id) {
      this.$refs.Close.click();

      var url = "orders/cancel-order/" + id;
      let response = await ApiClass.postNodeRequest(url, true);
      console.log({ response });

      if (response.data.status_code == 1) {
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "success",
          title: response.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
        var obj = { cancelId: id };
        this.$store.commit("setEditOrderInfo", JSON.stringify(obj));
        return;
      }

      this.$swal({
        toast: true,
        position: "top-end",
        icon: "error",
        title: response.data.message,
        showConfirmButton: false,
        timer: 3000,
      });
    },
  },
};
</script>


<style scoped>
/* .modal {
  background-color: #edf0f2bf;
} */
.modal-content {
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border: none;
  border-radius: 3px;
}

.modal-header h5 {
  font-family: var(--font-family);
  text-transform: capitalize;
  font-size: 16px;
  margin-bottom: 0;
  line-height: normal;
  font-weight: 500;
  color: #000;
}

.modal-header .btn-close:focus {
  box-shadow: none;
}
</style>