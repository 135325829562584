<template>
        <div class="tab-pane fade show active" id="pills-Order" role="tabpanel" aria-labelledby="pills-Order-tab">
            <div class="order_tab_box custom-scrollbar rounded-2 trading_main_box">
                <div id="orderdepth-loader" v-if="loader == true" class="spinner-border" role="status"
                    style="margin: 0 auto">
                    <span class="visually-hidden">Loading...</span>
                </div>
    
    
                <div class="container-fluid order-custom-table">
    
                    <!-- <div class="tradng-heading p-2 text-center">
                        <h6 data-v-599e8280="">Order History</h6>
                    </div> -->
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12 ">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Volume ({{ symboldata.currency }})</th>
                                        <th> Price ({{ symboldata.pair_with }})</th>
                                        <th>Market Width</th>
                                    </tr>
                                </thead>
    
                                <tbody>
                                    <tr v-for="(item, index) in orderbuy" :key="index">
                                        <td @click="update_atPrice(item.buyprice, item.volume.toFixed(5))">{{
                                                item.volume.toFixed(5)
                                        }}</td>
                                        <td @click="update_atPrice(item.buyprice, item.volume.toFixed(5))"
                                            class="green-color" style="text-align:center">{{ item.buyprice }}</td>
                                        <td @click="update_atPrice(item.buyprice, item.volume.toFixed(5))"
                                            class="green-color">{{ item.width }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12 border-top">
                            <table class="table">
    
                                <tbody>
                                    <tr v-for="(item, index) in ordersell" :key="index">
                                        <td @click="update_atPrice(item.sellprice, item.volume.toFixed(5))">{{
                                                item.volume.toFixed(5)
                                        }}</td>
    
                                        <td @click="update_atPrice(item.sellprice, item.volume.toFixed(5))" class="red-color"
                                            style="text-align:center">
                                            {{ item.sellprice }}
                                        </td>
                                        <td @click="update_atPrice(item.sellprice, item.volume.toFixed(5))"
                                            class="red-color">
                                            {{ item.width }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
    
                        <div class="col-xl-12" v-if="ordersell.length == 0 && orderbuy.length == 0 && loader == false">
                            <div  class="
                          d-flex
                          align-items-center
                          justify-content-center
                        " style="height: 300px">
                                <p>No orders get placed yet...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--order_tab_box-->
</template>
  
<script>
import ApiClass from "../api/api";
export default {
    name: "OrderDepth",
    data: () => ({
        marketdepthConnection: null,
        marketbuy: [],
        marketsell: [],
        orderbuy: [],
        ordersell: [],
        sell_price: "table_sell_row",
        buy_price: "table_buy_row",
        slug: "BTCUSDT",
        socketChk: false,
        loader: false,
        alldata: [],
        symboldata: []
    }),

    destroyed() {
        this.marketdepthConnection.close();
    },

    mounted() {
        this.slug = this.$route.params.slug;
        if (this.$store.getters.getMarketCurrency) {
            this.alldata = this.$store.getters.getMarketCurrency;

            var data = this.alldata?.filter((o) => {
                return o.symbol == this.slug;
            });
            this.symboldata = data[0];

            this.socketChk = data[0].listed;
        }
        // console.log("check==", this.socketChk);

        this.getMarketData(this.slug);
        var $this = this;
        this.marketdepthConnection = $this.socketChk
            ? new WebSocket(ApiClass.nodeWebsocket)
            : new WebSocket("wss://stream.binance.com:9443/ws");

        this.marketdepthConnection.onopen = function () {
            // console.log("Successfully connected to the market Depth...");
        };

        var ccStreamer = this.marketdepthConnection;
        const postfix = "@depth5@1000ms";
        this.marketdepthConnection.onopen = function onStreamOpen() {
            // console.log("socket check==", typeof $this.socketChk);
            var subRequest2 = "";
            if ($this.socketChk) {
                subRequest2 = {
                    method: "ADD",
                    params: [$this.slug.toLowerCase() + "@depth"],
                    id: 3,
                };
            } else {
                subRequest2 = {
                    method: "SUBSCRIBE",
                    params: [$this.slug.toLowerCase() + postfix],
                    id: 3,
                };
            }
            // console.log('socket responseeeeeeee====s',subRequest2);
            ccStreamer.send(JSON.stringify(subRequest2));

            ccStreamer.onmessage = function (event) {
                const obj = JSON.parse(event.data);

                // console.log(obj);

                $this.marketDepthMethode(obj);
            };
        };
    },
    methods: {

        update_atPrice(newPrice, newVolume) {
            var at_price = document.getElementById('at_price');
            var at_volume = document.getElementById('amount');
            var at_total = document.getElementById('total');
            at_price.value = newPrice;
            at_volume.value = newVolume;
            at_total.value = newVolume * newPrice;
        },
        marketDepthMethode(obj) {
            var $this = this;
            if (obj["lastUpdateId"]) {
                var depth_buy = obj["bids"];
                var depth_sell = obj["asks"];

                const depth_buy_sum_vol = $this.getArraySum(depth_buy);
                const depth_sell_sum_vol = $this.getArraySum(depth_sell);

                const MAX_Volume =
                    depth_buy_sum_vol > depth_sell_sum_vol
                        ? depth_buy_sum_vol
                        : depth_sell_sum_vol;

                $this.marketbuy = [];
                $this.marketsell = [];
                var x = [];
                var y = [];

                var arr = obj["bids"].concat(obj["asks"]);
                var all_volume_arr = [];
                for (const prop in arr) {
                    if (arr[prop][1] != 0) {
                        all_volume_arr.push(arr[prop][1]);
                    }
                }

                const MAX_ORDER_Volume = $this.getMAxOrderVolume(all_volume_arr);

                var order_buy = [];
                var order_sell = [];

                // MARKET DEPTH CALCULATION BUY SIDE
                for (const mainIndex in depth_buy) {
                    let d_price = parseFloat(depth_buy[mainIndex][0]);
                    let d_volume = parseFloat(depth_buy[mainIndex][1]);

                    const ADD_VOL =
                        mainIndex == 0 ? d_volume : d_volume + x[x.length - 1]["volume"];

                    x.push({
                        buyprice: d_price,
                        volume: ADD_VOL,
                        width: Math.floor($this.getRowWidth(ADD_VOL, MAX_Volume)) + "%",
                    });
                    //for order volume variable
                    order_buy.push({
                        buyprice: d_price,
                        volume: d_volume,
                        width:
                            Math.floor($this.getRowWidth(d_volume, MAX_ORDER_Volume)) + "%",
                    });
                }

                // MARKET DEPTH CALCULATION SELL SIDE

                for (const mainIndex in depth_sell) {
                    let d_price = parseFloat(depth_sell[mainIndex][0]);
                    let d_volume = parseFloat(depth_sell[mainIndex][1]);

                    const S_ADD_VOL =
                        mainIndex == 0 ? d_volume : d_volume + y[y.length - 1]["volume"];

                    y.push({
                        sellprice: d_price,
                        volume: S_ADD_VOL,
                        width: Math.floor($this.getRowWidth(S_ADD_VOL, MAX_Volume)) + "%",
                    });
                    //for order volume variable
                    order_sell.push({
                        sellprice: d_price,
                        volume: d_volume,
                        width:
                            Math.floor($this.getRowWidth(d_volume, MAX_ORDER_Volume)) + "%",
                    });
                }

                // SET DATA TO MAIN VARIABLES
                $this.loader = false;
                $this.marketbuy = x;
                $this.marketsell = y;
                $this.orderbuy = order_buy;
                $this.ordersell = order_sell;
            }
        },

        getSumAndGreater(array) {
            return {
                sum: this.getArraySum(array),
                max: this.getMAxOrderVolume(array),
            };
        },
        // for order voume
        getMAxOrderVolume(arr) {
            return Math.max(
                ...arr.map((e) => (Array.isArray(e) ? this.getMAxOrderVolume(e) : e))
            );
        },
        getArraySum(array) {
            return array
                .map((v) => parseFloat(v[1]))
                .reduce((sum, current) => sum + current, 0);
        },
        getRowWidth(vol, max_volume) {
            return (vol * 100) / max_volume;
        },

        myStyles(percentage, type) {
            var col1 = type == "green" ? "var(--bg-green)" : "var(--bg-red)";
            var col2 = "var(--white)";
            const direction = type == "green" ? "left" : "right";
            return {
                // width: wid,

                // background : "-webkit-gradient(linear, left top,right top, color-stop(" + percentage + "%," + col1 + "), color-stop(" + percentage + "%," + col2 + "))",
                // background : "-moz-linear-gradient(left center," + col1 + " " + percentage + "%, " + col2 + " " + percentage + "%)",
                // background : "-o-linear-gradient(left," + col1 + " " + percentage + "%, " + col2 + " " + percentage + "%)",
                background:
                    "linear-gradient(to " +
                    direction +
                    "," +
                    col1 +
                    " " +
                    percentage +
                    ", " +
                    col2 +
                    " " +
                    percentage +
                    ")",
            };
        },

        async getMarketData(symbol = "BTCUSDT") {
            let response2 = "";
            this.loader = true;
            if (this.socketChk) {
                var data_with = symbol.slice(symbol.length - 3, symbol.length);
                data_with = data_with == "SDT" ? "USDT" : data_with;
                var data = symbol.replace(data_with, "");
                response2 = await ApiClass.getNodeRequest(
                    "orders/order-book?currency=" + data + "&with_currency=" + data_with + '&limit=5',
                    false
                );
            } else {
                response2 = await ApiClass.getNodeRequest(
                    "list-crypto/market-data/" + symbol+'?limit=5',
                    false
                );
            }
            const market_data = response2.data;
            this.marketDepthMethode(market_data.data);
            this.loader = false;
        },
    },
};
</script>
  
<style scoped>
.order_tab_box {
    /* max-height: 10rem; */
    padding-bottom: 10px;
    overflow: scroll;
    background-color: var(--card-2);
    position: relative;
}

.nav-tabs {
    justify-content: end;
    background-color: var(--card-2);
    border-radius: 4px 4px 0px 0px;
}

.nav-tabs .nav-link:focus-visible {
    outline: none;
}

.tradng-heading h6 {
    margin-bottom: 0;
    text-transform: capitalize;
    font-size: 12px !important;
    font-weight: 600;
    color: var(--black);
}

.nav-tabs {
    border-bottom: none;
}

.nav-tabs .nav-link {

    font-weight: 600;
    text-transform: capitalize;
    font-size: 12px;
    color: var(--black);
    border: none;
}

.nav-tabs .nav-link.active {
    color: var(--nav-active);
    background-color: var(--card-2);
    border-radius: 0;
}

.border-top {
    border-color: var(--order-depth-td);
}

table.table {
    background-color: var(--card-2);
    margin-bottom: 0;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

table.table thead tr th {
    text-align: center;

    text-transform: capitalize;
    font-size: 11px;
    font-weight: 600;
    padding: 0.3rem 0.3rem;
    color: var(--depth-table-head);
}

.table>:not(caption)>*>* {
    border-bottom-width: 0;
}

table.table tbody {
    border-top: none;
}

table.table tbody tr {
    border: none !important;
}

table.table tbody tr td {
    text-align: center;
    width: 150px;
    text-transform: capitalize;
    font-size: 11px;
    font-weight: 500;
    color: var(--order-depth-td);
    border: none;
    padding: 0.3rem 0.8rem;
}

table.table tbody tr td.green-color {
    color: var(--green);
}

table.table tbody tr td.red-color {
    color: var(--red);
}

.table-main-1 {
    padding-right: 5px;
    border-right: 1px solid var(--upper-border);
}

.table-main-2 {
    padding-left: 5px;
}

@media all and (min-width: 1025px) and (max-width: 1199px) {
    .order_tab_box {
        margin-bottom: 10px;
    }
}

@media all and (min-width: 992px) and (max-width: 1024px) {
    .order_tab_box {
        margin-bottom: 10px;
    }
}

@media all and (min-width: 768px) and (max-width: 991px) {
    .order_tab_box {
        margin-bottom: 10px;
    }
}

@media all and (min-width: 320px) and (max-width: 767px) {
    .order_tab_box {
        margin-bottom: 15px;
        margin-top: 20px;
    }

    table.table thead tr th {
        font-size: 10px;
        padding: 5px 5px;
    }

    .nav-tabs {
        justify-content: center;
    }

    .order-custom-table {
        padding: 0;
    }
}

div#orderdepth-loader {
    position: absolute;
    top: 200px;
    left: 0;
    right: 0;
}

.tradng-heading {
    padding: 11px 11px;
}

tr:hover {
    cursor: pointer;
    background-color: var(--hover-text2) !important;
}

.order-custom-table {
    padding: 0px;
}
</style>