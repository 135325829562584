<template>
    <section id="main-orders-history" class="card-tm">
        <div class="card border-0">
            <div class="rounded-2">
            <ul class="nav nav-tabs p-2 border-0" id="pills-tab" role="tablist">
                <li class="nav-item fs-14" role="presentation" v-for="(item, index) in type" :key="index">
                    <button class="nav-link nav_btns fs-14 fw-400 rounded-3" :class="active_tab == item.id ? 'active' : ''"
                        @click="selectTab(item.id)" :id="`pills-${item.id}-tab`" data-bs-toggle="pill"
                        :data-bs-target="`#pills-${item.id}`" type="button" role="tab"
                        :aria-controls="`pills-${item.id}`" aria-selected="true"><span>{{
                            item.title
                        }}</span></button>
                </li>
            </ul>
            <div class="col-12 p-2">
                <OrdersHistoryTable :data="transactionData" @updateResponse="completeOrders"
                    @cancelModal="handleCancel" />
            </div>
        </div>
        </div>
        <Cancelmodal :orderDetail="orderdetails" />
    </section>

</template>
<script>
import OrdersHistoryTable from "@/components/Orders/OrdersHistoryTable.vue";
import ApiClass from "@/api/api";
import Cancelmodal from "@/components/modal/Cancelmodal.vue";
// import VueSkeletonLoader from "skeleton-loader-vue";
export default {
    name: "MainOrders",
    components: {
        OrdersHistoryTable,
        Cancelmodal
    },
    created() {
        // console.log(localStorage.getItem("token"));
        if (localStorage.getItem("token")) {
            this.completeOrders(1);
        }
    },
    data() {
        return {
            orderdetails: null,
            connection: "",
            active_tab: 'remaining',
            type: [
                { title: 'Open Orders', id: 'remaining' },
                { title: 'Order History', id: 'completed' },
            ],
            TransactionList: [],
            transactionData: {
                res: [],
                balances: [],
                skeletonLoad: false,
                loading: false,
                active_tab: 'remaining',
                from_page: 0,
                last_page: 0,
                current_page: 1,
                tableHeading: ['Pair', 'Type', 'Order Type', 'Price', 'Quantity', 'Pending Qty', 'Date', 'Status', 'Action'],
            },
        }
    },
    methods: {
        handleCancel(data) {
            this.orderdetails = data;
        },
        selectTab(id) {
            if (id != this.active_tab) {
                this.transactionData.active_tab = this.active_tab = id;
                let obj = this.transactionData;
                obj.from_page = obj.last_page = 0;
                obj.current_page = 1;

                if (id == 'remaining') {
                    obj.tableHeading = ['Pair', 'Type', 'Order Type', 'Price', 'Quantity', 'Pending Qty', 'Date', 'Status','Action'];
                }
                if (id == 'completed') {
                    obj.tableHeading = ['Pair', 'Type', 'Order Type', 'Price', 'Quantity', 'Date', 'Status'];
                }
                
                obj.res = obj.balances = [];
                this.completeOrders(1)
            }
        },

        async completeOrders(page) {
            let obj = this.transactionData;
            page == 1 ? obj.skeletonLoad = true : obj.loading = true;
            var url =
                "orders/get?type=" + this.active_tab + "&page=" + page + "&per_page=10";
            await ApiClass.getNodeRequest(url, true).then((response) => {
                if (response.data.status_code == 1) {
                    obj.skeletonLoad = false;
                    obj.loading = false;

                    obj.current_page = response.data.data.current_page;
                    obj.last_page = response.data.data.last_page;
                    let newArray = [...obj.balances, ...response.data.data.data];
                    obj.balances = obj.res = this.$helpers.getUniqArrObj(newArray);
                }
            });
        },
    },
    async mounted() {
        var userinfo = localStorage.getItem("user");

        if (userinfo) {
            var $this = this;
            var x = JSON.parse(userinfo);

            this.connection = new WebSocket(ApiClass.nodeWebsocket);
            this.connection.onopen = function () {
                // alert(
                //   "ORDERS Successfully connected to the echo websocket server..."
                // );
            };
            var ccStreamer = this.connection;
            this.connection.onopen = function onStreamOpen() {
                var request = {
                    method: "ORDER",
                    params: [x.referral_code],
                };
                ccStreamer.send(JSON.stringify(request));
            };

            this.connection.onmessage = function (event) {
                var msg = JSON.parse(event.data);
                //console.log("order socket===", msg);
                if (msg.update) {
                    $this.$store.commit("buySellFund", msg.update);
                    $this.transactionData.res = [];
                    $this.transactionData.balances = [];
                    $this.transactionData.current_page = 1,
                    $this.completeOrders(1);
                }
            };

            this.connection.onclose = function () {
                // console.log(
                //   "Successfully disconnected to the echo websocket server..."
                // );
            };
        }
    },
};
</script>
<style scoped>
.bg-open-order {
    background-color: var(--card-2);
}

.nav-tabs .nav-link {

    font-weight: 500;
    font-size: 14px;
    color: var(--text);
    min-height: 30px;
    border: none;
    border-radius: 0;
}

.nav-tabs .nav-link:focus-visible {
    outline: none;
}

.nav-tabs .nav-link.active {
    background-color: var(--bg4) !important;
    border: 0px !important;
    color: var(--onsurface) !important;
}

</style>