<template>
  <div class="table-container table-height table-responsive custom-scrollbar" id="tableContainer" ref="tableContainer"
    v-on:scroll="handleScroll">
    <table class="table table-borderless align-middle text-nowrap" ref="tablelist" id="table-list">
      <thead>
        <tr class="fs-13 fw-500 font-secondary m-0 text-nowrap">
          <th scope="col" class="" v-for="item in data.tableHeading" :key="item">{{ item }}
          </th>
        </tr>
      </thead>
      <tbody class="fiat-chart">
        <template v-if="data.skeletonLoad">
          <tr class="align-middle" v-for="i in data.tableHeading.length" :key="i">
            <td class="text-uppercase" v-for="j in data.tableHeading.length" :key="j">
              <VueSkeletonLoader type="react" :width="100" :height="15" animation="wave" color="#adb5bd"
                wave-color="#fff" :rounded="true" />
            </td>
          </tr>
        </template>
        <template v-if="data.res.length > 0">
          <tr class="border-0" v-for="(item, index) of data.res" :key="index">
            <td>
              <div class="d-flex gap-2 align-items-center">
                <h6 class="fs-14 m-0 fw-600">{{ item.currency }} / {{ item.with_currency }}</h6>
              </div>
            </td>
            <td class="text-center">
              <h6 class="subtitle text-capitalize">{{ item.type }}</h6>
            </td>
            <td class="text-center">
              <span class="badge text-capitalize"
                :class="item.order_type == 'buy' ? 'success-badge' : 'danger-badge'">{{
                  item.order_type
                }}</span>
            </td>
            <td class="text-center">
              <h6 class="subtitle">{{ $helpers.NumberFixed(item.at_price, 8) }}</h6>
            </td>
            <td class="text-center">
              <h6 class="subtitle">{{ $helpers.NumberFixed(item.quantity, 8) }}</h6>
            </td>
            <td class="text-center" v-if="data.active_tab == 'remaining'">
              <h6 class="subtitle">{{ $helpers.NumberFixed(item.pending_qty, 8) }}</h6>
            </td>
            <td class="text-center">
              <h6 class="subtitle">{{ new Date(item.created_at).toLocaleString("en-GB") }}</h6>
            </td>
            <td class="text-center">
              <h6 v-if="$route.path != '/orders' && data.active_tab == 'remaining'" class="subtitle text-capitalize">{{
                item.current_status
              }}</h6>
              <span v-else class="badge text-capitalize"
                :class="item.current_status == 'completed' || item.current_status == 'placed' ? 'success-badge' : 'danger-badge'">{{
                  item.current_status
                }}</span>
            </td>
            <td class="text-center" v-if="$route.path != '/orders' && data.active_tab == 'remaining'">
              <span data-bs-toggle="modal" data-bs-target="#cancelmodal" class="btn btn-danger order-cancelled-btn"
                @click="$emit('cancelModal', item)">Cancel</span>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div class="col-12 text-center" v-if="data.loading && !data.skeletonLoad">
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-if="data.res.length < 1 && !data.skeletonLoad"
      class="d-flex border-0 flex-column text-center justify-content-center py-4">
      <div>
        <img width="70" :src="require(`@/assets/images/icons/no-record.svg`)" />
      </div>
      <span class="fw-500 fs-14">No record found</span>
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
  name: "OrdersHistoryTable",
  components: {
    VueSkeletonLoader
  },
  props: {
    updateResponse: Function,
    cancelModal: Function,
    data: Object,
  },
  data() {
    return {
      currPage: this.data.current_page,
      loggedIn: false,
    }
  },
  // created() {
  //   // console.log(localStorage.getItem("token"));
  //   if (localStorage.getItem("token")) {
  //     this.loggedIn = true;
  //     // this.openOrders();
  //     this.completeOrders();
  //   }
  // },
  async mounted() {
    // alert(JSON.stringify(this.updateResponse))
    // if (this.data.balances) {
    //   this.loading = false;
    // }
  },
  computed: {
    computeBalance() {
      let qty = 0;
      for (let item of this.data.balances) {
        qty = parseFloat(item.quantity) + parseFloat(item.fund_quantity) + parseFloat(item.stake_quantity);
      }
      return qty.toFixed(4);
    },

    computeUsd() {
      let usd = 0;
      for (let item of this.data.balances) {
        usd = parseFloat(item.c_bal) + parseFloat(item.stake_bal) + parseFloat(item.fund_bal);
      }
      return usd.toFixed(4);
    }
  },
  methods: {
    handleScroll() {
      let el = this.$refs.tableContainer;
      let table = this.$refs.tablelist;

      if (this.currPage <= this.data.current_page && this.data.current_page < this.data.last_page) {
        if (el.scrollTop + el.clientHeight >= table.clientHeight) {
          this.currPage = this.data.current_page + 1;
          this.$emit('updateResponse', this.currPage);
        }
      }
    },
  },
  watch: {
    data:{
      deep: true,
        handler(newValue) {
          this.currPage = newValue.current_page
      }
    },
  }
};
</script>
<style scoped>
#tableContainer table th:not(:first-child) {
  text-align: center;
}

.table-height {
  height: auto;
  min-height: 15rem;
  max-height: 25rem;
  overflow-y: scroll;
}

.order-cancelled-btn {
  padding: 0rem 0.3rem;
  font-size: 10px;
  border-radius: 0.2rem;
}

/* 
.table thead th {
  color: var(--text);
} */
</style>
