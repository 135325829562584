<template>
  <div class="card-tm">
    <div class="exchange__top ">
<div class="price ">
  <span v-on:click="changeFavourite()" :class="IsStar == true ? 'star-fill mx-3' : 'star-blank mx-3'"><i
            :class="IsStar == true ? 'fas fa-star' : 'far fa-star'"></i></span>

        <a class="btn fw-boldb dropdown-toggle border"  data-bs-toggle="modal" data-bs-target="#staticBackdrop" role="button"
          aria-controls="offcanvasExample">
           <i class="fa fa-exchange" aria-hidden="true"></i> 

          {{ $route.params.slug }}
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-circle" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
</svg>
        </a>
       

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content card">
      <div class="modal-header border-0 mb-0 pb-0">
      <h2 class="modal-title fs-5"> Select Coin</h2>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-2">
        <ExchangeSidebar />

      </div>
     
    </div>
  </div>
</div>
      
            <div class="price-t fs-12">
        <h6 class="mb-0 fw-semibold fs-5" :class="slug_info.current_change >= 0 ?'font-green' : 'font-red'">
          <span v-if="slug_info.current_change >= 0">▲</span>
          <span v-else>▼</span>
          {{ slug_info.current_price }}
         </h6>
      </div>
      </div>

      <ul class="list ">
        <li>
          <p class="unit">24H Change</p>
          <p>
            <span :class="slug_info.current_change >= 0 ? 'font-green' : 'font-red'">{{
              slug_info.current_change
            }}%</span>
          </p>
        </li>
        <li>
          <p class="unit">24H High</p>
          <p><span style="color: var(--green);">{{ slug_info.current_high }}</span></p>
        </li>
        <li>
          <p class="unit">24H Low</p>
          <p><span style="color: var(--red);">{{ slug_info.current_low }}</span></p>
        </li>
        <li>
          <p class="unit">24h Volume </p>
          <p><span style="color: var(--red);">{{$helpers.NumberFixed((slug_info.current_volume * slug_info.current_price),3)
          }}</span></p>
        </li>

      </ul>
    </div>


    <!--exchange_upper_box-->
  </div>
</template>

<script>
import ApiClass from "../api/api";

import ExchangeSidebar from "@/components/ExchangeSidebar.vue";
export default {
  name: "ExchangeUpperBox",
  components: {
    ExchangeSidebar
  },
  data() {
    return {
      slug_info: {
        slug: this.$route.params.slug,
        current_price: "--",
        current_change: "--",
        current_high: "--",
        current_low: "--",
        current_volume: "--",
        current_currency: "",
        current_pair_with: "",
        image: "",
        name: "",
      },

      IsStar: null,
      alreadyFavourite: []
    }
  },
  mounted() {

    this.favouriteCryptos();
    //  this.tickerVolume();


  },
  methods: {

    async tickerVolume() {
      // const data= await ApiClass.getNodeRequest("list-crypto/get",true);
      //this.current_volume = data.data.data;
      //  console.log(data.data);
    },
    favouriteCryptos() {
      this.alreadyFavourite = this.$store.getters.favouriteCryptos;
      this.IsStar = (this.alreadyFavourite.find(item => item === this.slug_info.slug)) ? true : false;
    },
    changeFavourite() {
      var isIndex = this.alreadyFavourite.findIndex(item => item === this.slug_info.slug);
      (isIndex >= 0) ? this.alreadyFavourite.splice(isIndex, 1) : this.alreadyFavourite.push(this.slug_info.slug);
      this.$store.commit("SET_FAVOURITE", this.alreadyFavourite);
      this.favouriteCryptos();
      if (this.$store.getters.isLoggedIn) {
        (isIndex >= 0) ? this.callApi('DELETE', this.slug_info.slug) : this.callApi('CREATE', this.slug_info.slug);
      }
    },
    async callApi(method = null, param = null) {
      var pair_with = param.slice(param.length - 3, param.length);
      var sdt = (pair_with.indexOf('SDT') >= 0) ? true : false;
      pair_with = (sdt) ? 'USDT' : pair_with;
      var currency = (sdt) ? param.slice(0, param.length - 4) : param.slice(0, param.length - 3);
      if (method == 'CREATE') {
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "success",
          title: currency + pair_with + " Add in favourite List",
          showConfirmButton: false,
          timer: 3000,
        });
        await ApiClass.postRequest("favourite/create", true, { "currency": currency, "pair_with": pair_with });
      }

      if (method == 'DELETE') {
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: currency + pair_with + " Remove from favourite List",
          showConfirmButton: false,
          timer: 3000,
        });
        await ApiClass.deleteRequest("favourite/delete/" + param, true);
      }

    }
  },
  watch: {
    "$store.getters.getSlugInfo": function () {
      this.slug_info = this.$store.getters.getSlugInfo;
      console.log('slug info ===', this.slug_info);
    },
    "$route.params.slug": function () {
      this.favouriteCryptos();
    }
  }
}
</script>

<style scoped>
.offcanvas-body {
  padding: 0rem;
  overflow: hidden;
  background-color: var(--bg);
}

.offcanvas-start {
  top: 60px;
  left: 0;
  width: 290px;
  z-index: 9999 !important;
}

.exchange {
  position: relative;
}

.exchange_upper_box {

  border: 1px solid var(--border-color);
  padding: 10px 10px;
  background: var(--nav-bg);
}

.exchange_inner_box h3 {
  text-transform: capitalize;
  font-size: 13px;
  color: var(--onsurface);
  font-family: inherit;
  margin: 0;
}

.currency_name_box h3 {
  text-transform: uppercase;
}

.price_change_box h3 span {
  color: var(--green);
}

.exchange_inner_box.price_inner_box h3 {
  font-size: 13px;
}

.exchange_inner_box.price_inner_box h3 {
  font-size: 13px;
  padding-left: 5px;
}

.exchange_inner_box.price_change_box h3 {
  font-size: 13px;
}

.exchange_coin_dropdown {
  position: absolute;
  transform: translate3d(0px, 48px, 0px);
  top: 107px;
  left: 0px;
  will-change: transform;
  width: 20vw;
}

.exchange_inner_box.price_change_box h3 span {
  font-size: 13px;
  padding-left: 5px;
}

.exchange_inner_box.price_high_box h3 {
  font-size: 13px;
}

.exchange_inner_box.price_high_box h3 span {
  font-size: 13px;
  padding-left: 5px;
}

.exchange_inner_box.price_low_box h3 {
  font-size: 13px;
}

.exchange_inner_box.price_low_box h3 span {
  font-size: 16px;
  padding-left: 5px;
}

.t-info {
  font-size: 16px;
}



.exchange_inner_box.currency_name_box h3 span.star-fill {
  margin-right: 10px;
}

.exchange_inner_box.currency_name_box h3 span.star-fill .fas {
  color: var(--fav-color);
  cursor: pointer;
}

.exchange_inner_box.currency_name_box h3 span.star-blank {
  margin-right: 10px;
}

.exchange_inner_box.currency_name_box h3 span.star-blank .far {
  color: var(--fav-color);
  cursor: pointer;
}
</style>
